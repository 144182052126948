<template>
  <v-row>
    <v-col cols="12" md="4">
      <h5 v-t="'common.total_value'" class="grey100--text text-body-2" />
      <p class="mb-0 text--secondary lh-1-4 text-body-2">
        ${{ tradeValue | toScrap }}
      </p>
    </v-col>
    <v-col v-if="transaction.type === 'sell'" cols="12" md="4">
      <h5 v-t="'page.account.transactions.detail.bonus'" class="grey100--text text-body-2" />
      <p class="mb-0 lh-1-4 text-body-2">
        <span v-if="bonus.value" class="green400--text">
          +{{ bonus.value | toScrap }} ({{ bonus.amount * 100 | toLocale }}%)
        </span>
        <span v-else class="text--disabled">&mdash;</span>
      </p>
    </v-col>
    <v-col cols="12">
      <h5 class="grey100--text text-body-2">
        <span class="mr-1 text-capitalize" v-text="$tc('common.items', 2)" />
        <span class="blue300--text">(x{{ totalItems }})</span>
      </h5>
      <div class="d-flex flex-wrap">
        <div
          v-for="(item, i) in items"
          :key="i"
          class="game-item"
        >
          <v-tooltip top>
            <template #activator="{on}">
              <v-img
                :transition="false"
                :aspect-ratio="1"
                width="48"
                :src="`https://community.cloudflare.steamstatic.com/economy/image/${item.image}/164x164`"
                contain
                v-on="on"
              >
                <span v-show="item.amount > 1" class="item-amount">x{{ item.amount }}</span>
              </v-img>
            </template>
            <span>
              <span v-text="item.name" />
              <span v-if="item.amount > 1"> (x{{ item.amount }})</span>
            </span>
          </v-tooltip>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    itemdb: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tradeValue() {
      return this.transaction.data?.amount ?? this.transaction.amount
    },
    bonus() {
      return {
        amount: this.transaction.data?.bonus ?? 0,
        value: this.transaction.data?.bonusValue ?? 0,
      }
    },
    totalItems() {
      return this.items.reduce((acc, v) => acc + v.amount, 0)
    },
    items() {
      const { itemdb } = this
      const { items } = this.transaction

      return items?.map(item => ({
        ...itemdb[item.name],
        price: item.price,
        amount: item.amount ?? 1,
      })) ?? []
    },
  },
}
</script>

<style lang="scss" scoped>
  .game-item {
    .v-image ::v-deep .v-responsive__content {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .item-amount {
      text-shadow: 0 0 2px black;
      user-select: none;
    }
  }
</style>
